import { SyntheticEvent } from 'react'
import { Snackbar, Alert } from '@mui/material'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'

const ToastSnackbar = () => {
	const { error, removeError } = useErrorMessageContext()
	const { success, removeSuccess } = useSuccessMessageContext()

	const handleCloseErrorToast = (
		event: SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return
		}
		removeError()
	}

	const handleCloseSuccessToast = (
		event: SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return
		}
		removeSuccess()
	}

	return (
		<>
			{error && error.message && (
				<Snackbar
					open={!!error.message}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					autoHideDuration={7000}
					onClose={handleCloseErrorToast}
				>
					<Alert
						onClose={handleCloseErrorToast}
						severity="error"
						sx={{
							width: {
								xs: 300,
								sm: 360,
								md: 700,
								lg: '100%',
								xl: '100%'
							},
							height: '100%',
							fontSize: {
								xs: 12,
								sm: 14,
								md: 16,
								lg: 16,
								xl: 16
							},
							borderRadius: '10px'
						}}
					>
						{error.message}
					</Alert>
				</Snackbar>
			)}
			{success && success.message && (
				<Snackbar
					open={!!success.message}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					autoHideDuration={3000}
					onClose={handleCloseSuccessToast}
				>
					<Alert
						onClose={handleCloseSuccessToast}
						severity="success"
						sx={{ width: '100%', marginTop: { xxs: '5px', xs: '5px', sm: '0px' }, borderRadius: '10px' }}
					>
						{success.message}
					</Alert>
				</Snackbar>
			)}
		</>
	)
}

export default ToastSnackbar
