import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { lazy, Suspense, useContext, useEffect } from 'react'
import { LanguageContext } from './context/languageContext'
import Loader from './v1/components/common/Loader'

const AppV1 = lazy(() => import('./v1/App'))
const Login = lazy(() => import('./v1/layouts/login'))

const AppRouter = () => {
	const {
		dispatch: { setLanguage },
	} = useContext(LanguageContext)
	const location = useLocation()
	useEffect(() => {
		const searchString = location.search.toLowerCase()
		if (searchString.includes('lang')) {
			const urlLanguage = searchString.toLowerCase().split(':')[1]
			window.localStorage.setItem('language', urlLanguage)
			setLanguage(urlLanguage)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	return (
		<Suspense fallback={<Loader />}>
			<Routes>
				<Route path="/" element={<Navigate to="/v1/statistics" />} />
				<Route path="/login/:jwe" element={<Login />} />
				<Route path="/v1" element={<Navigate to="/v1/statistics" />} />
				<Route path="/v1/*" element={<AppV1 />} />
			</Routes>
		</Suspense>
	)
}

export default AppRouter
